<template>
  <LoadingDialog v-if="!error" />
  <div v-else>
    <v-card>
      <v-sheet dark color="error" class="pa-3">
        <div id="card-errors" role="alert">{{error}}</div>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import { decodeCampaignInfo, registerCampaignInfo, EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'
import LoadingDialog from '@/components/LoadingDialog'
import { lookupOwnerId } from '@/helpers/qr-redirects'

export default {
  components: {
    LoadingDialog
  },
  props: {
    campaignInfo: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      error: null
    }
  },
  async created () {
    const { campaignId, campaignType, ownerScenarioId, pathName, redirectId } = decodeCampaignInfo(this.campaignInfo)
    const { success, ownerId } = await lookupOwnerId(redirectId)
    if (success) {
      registerCampaignInfo({ campaignId, campaignType, ownerScenarioId, ownerId })
      const path = this.$router.resolve({ name: pathName, params: { ownerId, ownerScenarioId } }).href
      this.$router.replace({ path, query: { campaignInfo: this.campaignInfo } })
    } else {
      this.error = 'このQRコードは無効です'
    }
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_QR_REDIRECT)
  }
}
</script>

<style>
</style>
